import { Link } from "react-router-dom";
import { cn } from "../../utils";

export const AnchoreBtn = ({
  customClasses = "",
  url = "/",
  blank_page = false,
  onclick = () => {},
  children,
}) => {
  blank_page = blank_page ? "_blank" : "_self";

  return (
    <Link
      to={url}
      className={cn(`app-transition-in-150`, customClasses)}
      target={blank_page}
      onClick={onclick}
    >
      {children}
    </Link>
  );
};
