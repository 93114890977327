import React from "react";
import WriterCard from "../../organisms/Cards/WriterCard";
import { SectionOrderBtn } from "../OtherElements/SectionOrderBtn";
import axios from "axios";
import { writerEndPoint } from "../../../utils/request";
import { size } from "lodash";

export const RightBody = () => {
  const [writers, setWriters] = React.useState([]);

  // eslint-disable-next-line no-unused-vars
  const fetchWriters = React.useCallback(async () => {
    const response = await axios.get(
      `${writerEndPoint}/api/v1/writer/writer-app/dummy-profiles?per_page=4`
    );
    if (response?.status === 200) {
      setWriters(response?.data?.data || []);
    }
  }, []);

  React.useEffect(() => {
    // fetchWriters();
  }, []);

  if (!size(writers)) {
    return null;
  }

  return (
    <>
      <div className="grid md:grid-cols-2 gap-4 py-5">
        {/*auto-rows-min*/}
        {writers?.map((person) => (
          <WriterCard
            key={person.id}
            id={person.id}
            image={person?.image}
            name={person?.name}
            order_completed={person?.completed_orders}
            order_inprogress={person?.order_in_progress}
            about={person?.about_me || " "}
          />
        ))}
      </div>

      <SectionOrderBtn label="View More Writers" route="/find-writers" />
    </>
  );
};
